/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// We're including this file in app/views/layouts/application.html.haml - it
// should mostly only include application-wide Javascript. You should separate
// your Javascript into sensible files / components then make a decision how best
// to compose them to serve to the client.
//
// For example:
// The home page has a specific slideshow, some sort of calculator, an ad rotator etc.
// You could split these into calculator.js, slideshow.js and rotator.js then
// include them in a home.js pack which is only included on the home page.

import * as Sentry from '@sentry/browser';
// Notify Sentry of any js errors
if (process.env.NODE_ENV === 'production') {
  Sentry.init({ dsn: 'https://4554da79e8a549d49d8705cd986cbab8@sentry.epigenesys.org.uk/49' });
}

import Rails from "rails-ujs";
Rails.start();

import 'bootstrap';
import 'packs/select2_init';
import 'packs/technique_nav_collapse';


// Images
require.context('../images', true)

// Stylesheets
import 'stylesheets/bootstrap';
import 'stylesheets/typography';
import 'stylesheets/layout';
import 'stylesheets/hero';
import 'stylesheets/techniques';
import 'stylesheets/warning_page';
import 'stylesheets/pagination';
import 'stylesheets/search';
import 'stylesheets/definitions';
import 'stylesheets/home';

import '@fortawesome/fontawesome-pro/css/all';

// Support component names relative to this directory:
var componentRequireContext = require.context("pages", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);