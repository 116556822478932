import React from 'react';
import { Provider } from 'react-redux';
import { combineReducers, createStore, applyMiddleware } from 'redux';

import { TechniqueReducer, FormReducer, TagsReducer } from 'reducers/techniques';
import SentryErrorBoundary from 'components/Sentry/SentryErrorBoundary';
import Technique from '../components/techniques_management/Technique';
import {
  subheadingFormValidationMiddleware,
  saveSubheadingMiddleware,
  paragraphFormValidationMiddleware,
  saveParagraphMiddleware,
  moveElementMiddleware,
  deleteElementMiddleware,
  videoFormValidationMiddleware,
  saveVideoMiddleware,
  imageFormValidationMiddleware,
  saveImageMiddleware
} from 'middleware/techniques';

const TechniqueManagement = (props) => {
  const store = createStore(
    combineReducers({ technique: TechniqueReducer, form: FormReducer, tags: TagsReducer }),
    { 
      technique: props.technique,
      tags: props.technique.available_tags.map((tag) => { return({label: tag.name, value: tag.id}); })
    },
    applyMiddleware(
      subheadingFormValidationMiddleware,
      saveSubheadingMiddleware,
      paragraphFormValidationMiddleware,
      saveParagraphMiddleware,
      moveElementMiddleware,
      deleteElementMiddleware,
      videoFormValidationMiddleware,
      saveVideoMiddleware,
      imageFormValidationMiddleware,
      saveImageMiddleware
    )
  );

  return (
    <SentryErrorBoundary>
      <Provider store={store}>
        <Technique></Technique>
      </Provider>
    </SentryErrorBoundary>
  )
}

export default TechniqueManagement;
